import * as React from "react"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const Map = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: 9.391648, lng: 99.965102}} >
        <Marker position={{ lat: 9.421115, lng: 99.979522 }} />
  </GoogleMap>
));

export default Map;

import React, { useRef, useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa"
import { MdTimer } from "react-icons/md"
import BackgroundImage from "gatsby-background-image"
import { getImage, StaticImage } from "gatsby-plugin-image"
import NavBar from "../components/navbar"
import SectionTitle from "../components/sectiontitle"
import Footer from "../components/footer"
import PopUp from "../components/popup"
import Map from "../components/googleMap"
import { Slide } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import HeadData from "../data/HeadData"
import JotformEmbed from 'react-jotform-embed'
import { convertToBgImage } from "gbimage-bridge"
import HomeAboutSection from "../components/sections/home-about-section"

const YoutubeLoadable = loadable(() =>
  pMinDelay(import("../components/loadable-youtube"), 500)
)

const IndexPage = () => {
  const slideRef = useRef()
  const subscribe = useRef()
  const name = useRef()
  const email = useRef()
  const [previousIndex, setPreviousIndex] = useState(0)
  const [nextIndex, setNextIndex] = useState(0)
  const [nameValue, setnameValue] = useState("")
  const [emailValue, setemailValue] = useState("")
  const [emailSubscribeValue, setemailSubscribeValue] = useState("")
  const [emailSubscribeValMsg, setemailSubscribeValMsg] = useState("")
  const [nameValMsg, setnameValMsg] = useState("")
  const [emailValMsg, setemailValMsg] = useState("")
  const [showvideoPopup, setshowVideoPopup] = useState()
  const [showIntroVideo, setshowIntroVideo] = useState()
  const [currentPlayer, setcurrentPlayer] = useState()

  const slideProperties = {
    autoplay: false,
    indicators: false,
    arrows: false,
    transitionDuration: 500,
    onChange: (previous, next) => {
      setPreviousIndex(previous)
      setNextIndex(next)
    },
  }

  const { location, mainBanner } = useStaticQuery(
    graphql`
      {
        location: file(relativePath: { eq: "location.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
        }
        mainBanner: file(relativePath: { eq: "main-banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
        }
      }
    `
  )

  const locationImg = getImage(location)
  const locationBG = convertToBgImage(locationImg)

  const mainBannerImg = getImage(mainBanner)
  const mainBannerBg = convertToBgImage(mainBannerImg)

  const handleValidation = e => {
    if (nameValue.trim() === "") {
      name.current.focus()
      setnameValMsg("The field is required.")
    } else setnameValMsg("")

    if (validateEmail(emailValue)) setemailValMsg("")
    else {
      email.current.focus()
      setemailValMsg("Provide a valid email address.")
    }

    e.preventDefault()
  }

  const validateEmail = mail => {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      mail
    )
  }

  return (
    <div>
      <HeadData isHome />
      <PopUp
        open={showIntroVideo}
        handleClose={() => {
          if (currentPlayer !== undefined) {
            currentPlayer.target.stopVideo()
          }
          setshowIntroVideo(false)
        }}
      >
        <YoutubeLoadable
          id="info"
          videoId="Zbr8DwgXABg"
          className="flex h-full w-full m-0"
          containerClassName="flex h-60 lg:h-600px w-full lg:w-3/4 m-0 p-1 bg-linkColor"
          onPlay={e => {
            setcurrentPlayer(e)
          }}
        />
      </PopUp>
      <PopUp
        open={showvideoPopup}
        handleClose={() => {
          if (currentPlayer !== undefined) {
            currentPlayer.target.stopVideo()
          }
          setshowVideoPopup(false)
        }}
      >
        <YoutubeLoadable
          id="info"
          videoId="GnE5b0ntfq4"
          className="flex h-full w-full m-0"
          containerClassName="flex h-60 lg:h-600px w-full lg:w-3/4 m-0 p-1 bg-linkColor"
          onPlay={e => {
            setcurrentPlayer(e)
          }}
        />
      </PopUp>
      <NavBar key="navbar" />
      {/* "transition duration-500 ease-in-out bg-blue-600 
      hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 ..." */}
      <div className="flex h-screen bg-no-repeat bg-center z-0">
        <BackgroundImage
          className="h-full w-full"
          {...mainBannerBg}
          backgroundColor={`#040e18`}
        >
          &nbsp;
        </BackgroundImage>
      </div>
      <div className="absolute w-full top-2/4 h-36 pr-2 pl-2 md:pl-10 lg:pl-18 xl:pl-36 ">
        <div className="text-xl md:text-4xl font-sans font-bold text-white animate-drop1 transform scale-0 ">
          <FaPhoneAlt className="inline-block text-lg md:text-3xl" />
          <span className="inline-block pl-2">+66 81 007 8921</span>
        </div>
        <div className="w-full flex flex-row relative">
          <h1 className="w-10/12 lg:w-11/12 animate-dropTop z-50 transform scale-0 ">
            <span
              className="text-4xl sm:text-5xl md:text-7xl 
            font-sans font-bold text-white"
            >
              DISC GOLF THAILAND
            </span>
          </h1>
          <div className="w-2/12 lg:w-1/12 relative flex h-24 justify-start items-start ">
            <button
              className="w-18 h-18 focus:outline-none"
              onClick={e => {
                setshowIntroVideo(true)
                e.preventDefault()
              }}
            >
              <div className="relative flex justify-start items-start">
                <StaticImage
                  src="../images/play-icon.png"
                  className="cursor-pointer
                transition duration-300 ease-in-out transform hover:scale-125"
                  alt="Play Intro"
                />
              </div>
            </button>
          </div>
        </div>
        <div className="relative animate-drop2 transform scale-0">
          <span className="text-sm md:text-2xl text-white font-sans ">
            Laem Sor Beach Disc Golf And Acoustic Bar
          </span>
        </div>
        <div className="relative text-xs md:text-xl text-linkColor font-sans animate-drop3 transform scale-0">
          <FaMapMarkerAlt className="inline-block" /> 25 moo 4, Tambon Na
          Mueang, Koh Samui, Chang Wat Surat Thani 84140, Thailand
        </div>
      </div>
      <HomeAboutSection />

      {/* PHOTO GALLERY */}
      <div id="gallery">
        <div className="relative bg-transparent">
          <div className="pt-10 font-sans text-center max-w-5xl mx-auto bg-white px-4 sm:px-4">
            <SectionTitle
              title="PHOTO GALLERY"
              desc="Come and Join Us and Have Some Fun"
            />
            <div className="flex flex-col sm:grid sm:grid-cols-4 sm:grid-rows-8 sm:h-1000px bg-gray-400">
              <div className="flex md:col-span-1 md:row-span-1 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/throw1.jpg"
                    alt="Image 1"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="col-span-1 row-span-2 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/discs.jpg"
                    alt="Image 2"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="col-span-1 row-span-1 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/DgolfSmall-52.jpg"
                    alt="Image 3"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="col-span-1 row-span-1 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/throw2.jpg"
                    alt="Image 4"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="col-span-1 row-span-2 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/DgolfSmall-67.jpg"
                    alt="Image 5"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="col-start-2 col-span-1 row-span-1 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                    src="../images/throw2.jpg"
                    alt="Image 6"
                  />
                </div>
              </div>
              <div className="col-start-3 row-start-2 col-span-1 row-span-2 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                    src="../images/DgolfSmall-55.jpg"
                    alt="Image 7"
                  />
                </div>
              </div>
              <div className="col-start-4 row-start-2 col-span-1 row-span-1 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                    src="../images/throw3.jpg"
                    alt="Image 8"
                  />
                </div>
              </div>
              <div className="col-start-4 row-start-3 col-span-1 row-span-1 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                    src="../images/throw4.jpg"
                    alt="Image 9"
                  />
                </div>
              </div>
              <div className="flex md:col-span-1 md:row-span-1 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/new-photo-5.jpg"
                    alt="Image 10"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="col-span-1 row-span-4 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/new-photo-1.jpg"
                    alt="Image 11"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="flex md:col-span-1 md:row-span-1 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/new-photo-6.jpg"
                    alt="Image 13"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="col-span-1 row-span-4 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/new-photo-3.jpg"
                    alt="Image 12"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>              
              <div className="col-span-1 row-span-3 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/new-photo-2.jpg"
                    alt="Image 12"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
              <div className="col-span-1 row-span-3 overflow-hidden">
                <div className="flex h-full w-full">
                  <StaticImage
                    src="../images/new-photo-4.jpg"
                    alt="Image 12"
                    className="m-0 h-full w-full object-cover transition duration-300 ease-in-out transform hover:scale-125"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-10 w-full h-full lg:h-600px">
        <div className="h-600px w-full lg:w-4/12 bg-yellow-600">
          <StaticImage
            src="../images/DgolfSmall-59.jpg"
            alt="Image"
            className="bg-no-repeat bg-cover bg-center h-full w-full"
          />
          <div className="relative flex w-full -top-1/2 justify-center items-start">
            <button
              className="w-18 h-18 focus:outline-none"
              onClick={e => {
                setshowVideoPopup(true)
                e.preventDefault()
              }}
            >
              <div className="relative flex justify-start items-start overflow-visible">
                <StaticImage
                  src="../images/play-icon.png"
                  alt="Play Action"
                  className="bg-no-repeat bg-cover bg-center h-18 w-18 cursor-pointer
                  transition duration-300 ease-in-out transform hover:scale-125"
                />
              </div>
            </button>
          </div>
        </div>
        <div className="h-600px w-full lg:w-4/12 bg-linkColor min-h-500px p-4">
          <div className="flex flex-col h-full w-full border border-dashed border-white py-3">
            <div>
              <div className="flex flex-row flex-wrap pt-4 px-4">
                <div className="w-1/4 xsm:w-1/6 sm:w-1/12 lg:w-3/12 text-right m-0 pr-2">
                  <StaticImage src="../images/about-icon.png" alt="About" />
                </div>
                <div className="w-3/4 xsm:w-5/6 sm:w-11:12 lg:w-9/12 m-0 px-2">
                  <div className="w-full m-0 px-2">
                    <h3 className="m-0 text-white">
                      Third Annual Hyzenbrownie Open
                    </h3>
                  </div>
                  <div className="w-full m-0 px-2 pt-2">
                    <span className="text-white font-sans text-sm">
                      The Flaskenpouch 2018
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow py-4">
              <p className="text-white font-sans text-xs leading-normal sm:text-sm text-center lg:text-left pt-3 sm:pt-5 px-1 sm:px-10">
                All players will play 2 rounds of 13 holes on Saturday and 1
                round of 13 holes on Sunday, for a total of 39 holes. The top 4
                players in the professional divisions (Open Men, Open Masters
                Men, and Open Women) will also play a Final 9 holes on an
                extended finals layout.
              </p>
            </div>
            <div>
              <div className="text-center">
                <div className="pb-5 ">
                  <a
                    href="https://www.facebook.com/events/2430456163851973/"
                    target="_blank"
                    title="Register Now!"
                  >
                    <div className="bg-red-600 text-white w-48 mx-auto font-sans text-xs py-2 rounded-full">
                      <strong>REGISTER NOW</strong>
                    </div>
                  </a>
                </div>
                <div className="pb-5">
                  <a
                    href="https://www.facebook.com/events/2430456163851973/"
                    target="_blank"
                    title="Event Schedule"
                  >
                    <div className="text-white w-48 mx-auto font-sans text-xs py-2 rounded-full border-solid border border-white">
                      <strong>EVENT SCHEDULE</strong>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-600px w-full lg:w-4/12 bg-gray-600 min-h-500px">
          <div className="h-full">
            <Slide ref={slideRef} {...slideProperties}>
              <div className="flex flex-grow h-full w-full min-h-600px items-center justify-center">
                <StaticImage
                  className="h-full w-full bg-no-repeat bg-center bg-cover"
                  src="../images/DgolfSmall-46.jpg"
                  alt="Slide 1"
                />
              </div>
              <div className="flex flex-grow h-full w-full min-h-600px items-center justify-center">
                <StaticImage
                  className="h-full w-full bg-no-repeat bg-center bg-cover"
                  src="../images/DgolfSmall-65.jpg"
                  alt="Slide 2"
                />
              </div>
              <div className="flex flex-grow h-full w-full min-h-600px items-center justify-center">
                <StaticImage
                  className="h-full w-full bg-no-repeat bg-center bg-cover"
                  src="../images/DgolfSmall-39.jpg"
                  alt="Slide 3"
                />
              </div>
            </Slide>
          </div>
          <div className="relative flex justify-center flex-grow w-full h-10 -mt-16 mx-0">
            <button
              className={`w-2 h-2 ${
                nextIndex === 0 ? "bg-white" : ""
              } hover:bg-white bg-none rounded-full ring-2 ring-white focus:outline-none focus:shadow-none `}
              onClick={e => {
                slideRef.current.goTo(0)
                e.preventDefault()
              }}
            >
              &nbsp;
            </button>
            <button
              className={`w-2 h-2 ${
                nextIndex === 1 ? "bg-white" : ""
              } hover:bg-white rounded-full ring-2 ring-white mx-2 focus:outline-none focus:shadow-none`}
              onClick={e => {
                slideRef.current.goTo(1)
                e.preventDefault()
              }}
            >
              &nbsp;
            </button>
            <button
              className={`w-2 h-2 ${
                nextIndex === 2 ? "bg-white" : ""
              } hover:bg-white rounded-full ring-2 ring-white focus:outline-none focus:shadow-none`}
              onClick={e => {
                slideRef.current.goTo(2)
                e.preventDefault()
              }}
            >
              &nbsp;
            </button>
          </div>
        </div>
      </div>

      {/* Subscribe */}
      {/* <div className="relative flex flex-col bg-myGray1 pt-10 z-10">
      <div>
        <SectionTitle title="SUBSCRIBE" desc="Receive Update from Us"/>
      </div>
      <div className="flex-grow w-8/12 lg:w-3/12 md:w-4/12 mx-auto mt-5">
        <div className="flex flex-col w-full h-full">
          <div>
            <span className="text-xs font-sans text-gray-600">Email Address</span>
          </div>
          <div>
            <input ref={subscribe} type="email" onChange={(e) => {setemailSubscribeValue(e.target.value)}}
              className={`w-full border border-gray-400 text-xs font-sans focus:outline-none p-1
                ${emailSubscribeValMsg.trim() !== '' && 'border-red-600'}`}/>
            <span className="text-xs text-red-600 font-sans">{emailSubscribeValMsg}</span>
          </div>
          <div className="flex-grow items-center align-middle justify-center mb-5">
            <div className="flex h-full justify-center mt-5">
              <button onClick={() =>
                {
                  if(!validateEmail(emailSubscribeValue))
                    {
                      subscribe.current.focus();
                      setemailSubscribeValMsg('Provide a correct email address.')
                    }
                  else
                    setemailSubscribeValMsg('');
                }}
                className="bg-linkColor w-6/12 sm:2/12 h-8 text-white font-sans text-xs opacity-80 hover:opacity-100">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      {/* Location */}
      <div id="location" className="relative">
        <div className="relative">
          <div className="flex flex-col pt-10">
            <div>
              <SectionTitle
                title="LOCATION"
                desc="Check out our location information"
              />
            </div>
            <a
              href="https://goo.gl/maps/oeuDMpiy6EPUwymr7"
              target="_blank"
              title="Map Location"
            >
              <div className="flex h-600px align-middle justify-center">
                <BackgroundImage
                  className="h-full w-full"
                  {...locationBG}
                  backgroundColor={`#040e18`}
                >
                  &nbsp;
                </BackgroundImage>
              </div>
            </a>
            {/* <Map
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDAhN0LBgrsmrg05xasKdoPLYx3Xj5srSo&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `600px`, width: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />} /> */}
          </div>
        </div>
      </div>

      <div id="contactUs" className="relative bg-transparent">
        <div className="relative">
          <div className="flex flex-col md:flex-row pt-10 bg-myGray1 justify-center">
            <JotformEmbed src="https://form.jotform.com/221590720133042" />

            <div className="w-full md:w-6/12 md:max-w-md pt-5 pl-5 pb-5 px-2">
              <h4 className="text-linkColor mb-2">Get in Touch</h4>
              <span className="text-sm font-sans text-gray-500">
                Have a question or comment on one of our products? Feel free to
                use the form below or contact us by phone or fax, and an Disc
                Golf Thailand representative will get back to you as soon as
                possible.
              </span>
              <div className="pt-4 p-1 px-8">
                <hr className="bg-gradient-to-r from-gray-100 via-gray-400 to-gray-100"></hr>
              </div>
              <h4 className="text-linkColor mb-2">
                The Office DISC GOLF THAILAND
              </h4>
              <div className="flex flex-wrap flex-row">
                <div className="flex w-1/12 justify-center items-start py-2">
                  <div className="flex w-6 h-6 bg-blue-400 justify-center items-center rounded-full">
                    <span className="text-white text-xs">
                      <FaMapMarkerAlt />
                    </span>
                  </div>
                </div>
                <div className="flex w-11/12 items-start py-2">
                  <strong className="text-sm font-sans text-gray-500">
                    Address:
                  </strong>
                  <span className="text-sm font-sans text-gray-500  pl-2">
                    25 moo 4, Tambon Na Mueang, Koh Samui, Chang Wat Surat Thani
                    84140
                  </span>
                </div>
                <div className="flex w-1/12 justify-center items-start py-2">
                  <div className="flex w-6 h-6 bg-blue-400 justify-center items-center rounded-full">
                    <span className="text-white text-xs">
                      <FaPhoneAlt />
                    </span>
                  </div>
                </div>
                <div className="w-11/12 items-start py-2">
                  <strong className="text-sm font-sans text-gray-500">
                    Phone:
                  </strong>
                  <span className="text-sm font-sans text-gray-500 pl-2">
                    +66 81 007 8921
                  </span>
                </div>
                <div className="flex w-1/12 justify-center items-start py-2">
                  <div className="flex w-6 h-6 bg-blue-400 justify-center items-center rounded-full">
                    <span className="text-white text-xs">
                      <FaEnvelope />
                    </span>
                  </div>
                </div>
                <div className="w-11/12 items-start py-2">
                  <strong className="text-sm font-sans text-gray-500">
                    Email:
                  </strong>
                  <span className="text-sm font-sans text-gray-500 pl-2">
                    info@discgolfthailand.com
                  </span>
                </div>
              </div>
              <div className="pt-4 p-1 px-8">
                <hr className="bg-gradient-to-r from-gray-100 via-gray-400 to-gray-100"></hr>
              </div>
              <h4 className="text-linkColor mb-2">Business Hours</h4>
              <div className="flex flex-wrap flex-row">
                <div className="flex w-1/12 justify-center items-center text-xs">
                  <MdTimer />
                </div>
                <div className="w-11/12 pb-1">
                  <span className="text-xs font-sans text-gray-500">
                    Monday - Friday 9am to 11pm
                  </span>
                </div>
                <div className="flex w-1/12 justify-center items-center text-xs">
                  <MdTimer />
                </div>
                <div className="w-11/12 pb-1">
                  <span className="text-xs font-sans text-gray-500">
                    Saturday - 9am to 11pm
                  </span>
                </div>
                <div className="flex w-1/12 justify-center items-center text-xs">
                  <MdTimer />
                </div>
                <div className="w-11/12 pb-1">
                  <span className="text-xs font-sans text-gray-500">
                    Saturday - 9am to 11pm
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default IndexPage

import { Link } from "gatsby"
import React from "react"
import SectionTitle from "../sectiontitle"

const HomeAboutSection = () => {
  return (
    <div className="pt-10 font-sans text-center max-w-5xl mx-auto bg-white px-0 sm:px-10 flex flex-col items-center">
      <SectionTitle title="Disc Golf in Thailand at Laem Sor Beach Disc Golf Course." />
      <span className="inline-block px-5 sm:px-1 text-xs sm:text-sm">
        25 moo 4, Tambon Na Mueang, Koh Samui, Chang Wat Surat Thani 84140
        <br />
        <br />
        Located just 10 minutes south of Lamai, Laem Sor Beach Disc Golf and
        Acoustic Cafe is Koh Samui’s premiere 18 hole disc golf course and
        offers a relaxed atmosphere for people looking to break away from the
        crowds and explore the more natural side of Koh Samui. Surrounded by
        landscaped fairways and greens just off the beach, the tropical fruit
        trees, exotic birds and butterflies embellish the rustic atmosphere
        which make this place a truly unique and captivating place to visit
        while on Koh Samui. Home made western/ thai food and cold drinks are
        available with live music on occasion in the evening time. Affordable
        teak wood guesthouse and treetop bungalow. Public restrooms. Wifi
        internet access. Taxi service and Motorbike rental. Fishing, sailing and
        kayaking boats for rent and charter.
      </span>
      <Link to="/about" className="mt-5">
        <div className="bg-linkColor w-32 text-sm py-2 text-white font-sans opacity-80 hover:opacity-100">
          {"Read More >"}
        </div>
      </Link>
    </div>
  )
}

export default HomeAboutSection

import React from "react"
import {FaWindowClose} from 'react-icons/fa';

const PopUp = (props) => {
    return (
        <div className={`flex flex-col items-center justify-center px-1 lg:p-0 bg-gray-900 h-full w-full fixed z-50 bg-opacity-50 -top-full ${props.open === false ? 'animate-popDownWindow' : props.open === true && 'animate-popUpWindow'} `}>
            <div className="flex flex-col w-full lg:w-3/4 items-end">
                <div className='flex flex-grow relative bg-white top-5'>
                    <FaWindowClose className='text-black text-lg cursor-pointer hover:text-red-600 transition duration-300 ease-out transform hover:scale-110'
                        onClick={props.handleClose}
                    />
                </div>
            </div>
            {props.children}
        </div>
    )
}

export default PopUp;

import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SectionTitle = ({title, desc}) => (
    <div className="w-full text-center">
        <span className="block">
            <h2 className="inline-block pt-5 px-5 m-0">{title}</h2>
        </span>
        <span className="inline-block mt-3 h-1 w-36 sm:w-48 bg-green-500 rounded-sm">&nbsp;</span>
        <span className="block text-gray-400 font-sans text-xs -mt-3 mb-5">{desc}</span>
    </div>
)

export default SectionTitle;